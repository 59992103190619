import * as React from 'react';
import { Link } from 'gatsby';
import SEO from '../components/shared/SEO';
import Container from '../components/shared/Container';

// markup
const NotFoundPage = () => (
  <Container>
    <SEO title="Kuma Learn - Page Not Found" />
    <div className="max-w-7xl mx-auto px-4 pt-6 sm:px-8">
      <div className="bg-white">
        <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">Page not found</span>
          </h2>
          <p className="mt-4 text-lg leading-6 text-200">
            Sorry, I couldn’t find what you were looking for <span role="img" aria-label="Pensive emoji">😔</span>
          </p>
          <div className="mt-8 flex justify-center">
            <div className="inline-flex rounded-md shadow">
              <Link to="/" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700">
                Go home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

export default NotFoundPage;
